<template>
  <b-container class="home">

    <img src="https://geospatialmedia.s3.amazonaws.com/wp-content/uploads/2019/03/Data-Science-vs.-Big-Data-vs.jpg" class="home-img" alt="home img">
  <b-card class="tag mx-auto" style="width: 85%;
    border: none;">
    <h3>About Bussma</h3>
    <b-card-text>
      Bussma delivers a comprehensive solutions for managing Bigdata and Data-Science use cases; including Predictive-Analytics Machine-Learning, and Data Management area of projects such Data Integration, Data Warehousing (DWH), Operational Data Store (ODS), Real Time Integration, Data Migration, Data Discovery, Data De-duplication, Data Quality, Data Governance, Metadata Management, Master Data Management (MDM), Big Data, Data Archiving, Data Retirement, Data Masking, Subsetting, Test Data Management (TDM), Business Rules projects, Complex Event Processing, Proactive Monitoring..etc
      Bussma provides smart solutions based on the state-of-the-art AI techniques. The proposed solutions span various domains including, but not limited to; - Banking (Sentiment Analysis, Fraud Detection, Customer Churn Prediction, Predict Best Next Offer, ATM Replenishment). - Health care (Hospital Acquired Infection, Evidence Based Medicine, Electronic Health Record, Reducing Hospital Readmission, and Reducing Patients No Shows). - Telecommunications (Predict Mobile Internet Signal Storming, Recommend Mobile Internet Optimal Load).
      Bussma has more than 60 customers represent different business lines including Banking and Finance, Telecommunication, Government, Transportation, Retail, etc. Bussma has customer in Saudi Arabia, United Arab Emirates, Qatar, Kuwait, Oman, Egypt, Iraq, Algeria, Tunisia, Morocco, Nigeria, Pakistan, Bangladesh and Maldives.
    </b-card-text>
 </b-card>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
};
</script>
<style scoped>
.home{
    padding-left: inherit;
  padding-right: inherit;
   position: relative;
   padding-bottom: 14rem;
}
.home-img{
background-position: 0 0 !important;
    background-repeat: no-repeat !important;
       min-width: 77pc;
    width: -webkit-fill-available;
    height: 36pc;
}
.card-text{
      color: #6B6B6B;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
}
h3{
  text-align: center;
}
.tag {
       float: left;
       position: absolute;
       left: 0px;
       bottom: 0px;
       z-index: 1000;
       padding: 5px;
       font-weight: bold;
}
</style>
