<template>
    <b-navbar toggleable="lg"  >
    <b-navbar-brand href="/" style=" color: rgba(0, 0, 0, 0.5)!important">
      <img src="../assets/IMG_9574.png" alt="Bussma Logo" class="nav-bar-img">
      Bussma
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav  class="ml-auto" style="display: inline-flex" right>
                    <b-nav-item  to="/Customers">Our Customers</b-nav-item>
                    <b-nav-item  to="/Solutions">Our Solutions</b-nav-item>
                    <b-nav-item  to="/Contact">Contact Us</b-nav-item>
        </b-navbar-nav>
    </b-collapse>
    </b-navbar>
</template>

<script>
export default {};
</script>

<style scoped>
.nav-bar-img{
  height: 50px;   ;
}
.navbar{
  /* padding: 0.5rem 7rem !important; */
  box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%), inset 0 -1px 5px rgb(0 0 0 / 25%);
}
</style>
