<template>
  <b-container class="footer">
     <b-row style="    margin-right: unset;  margin-left: unset;"> 
        <b-col md="5">
            <img src="../assets/IMG_9574.png" alt="Bussma Logo" style="height: 110px;">
        </b-col>
        <b-col md=2 >
            <h5>Company </h5>
            <b-link  class="footer-link" to="/About">About Bussma</b-link>
            <b-link  class="footer-link" to="/Customers">Our Customers</b-link>
            <b-link  class="footer-link" to="/Solutions">Our Solutions</b-link>
       </b-col>    
       <b-col  md=2>
            <h5>How we can help?</h5>
            <b-link  class="footer-link" to="/Contact">Contact Us</b-link>

       </b-col>
     </b-row>
      <b-row style="border-top: 2px solid #dbddf5;    margin-right: unset;
    margin-left: unset;">    
          <b-col style=" color: #595959;   text-align: center;" md="6" offset-md="3">ALL RIGHTS RESERVED 2021</b-col>
       </b-row>
  </b-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.row{
  padding-top: 10px;padding-bottom: 10px;
}
.footer{
    background-color: #eceeff !important;
}
.footer-link{
      display: block;
       color: rgb(89, 89, 89)!important
}
h5{
  color: black;
}
</style>