<template>
  <div id="app">
    <loader v-if="loadingCount >0" />
    <nav-bar></nav-bar>
    <div class="context-wrapper">
      <router-view class="context" />
      <bottom-navBar class="footer-wrapper"></bottom-navBar>

    </div>
  </div>
</template>
<script>
import navBar from "../src/components/navBar.vue";
import bottomNavBar from "../src/components/Footer.vue";
import loader from "../src/components/Loader.vue";
export default {
  name: "App",
  components: { navBar,loader,bottomNavBar}
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
   display: flex;
  flex-direction: column;
  height: 100%;
}
.footer-wrapper{
      /* position: inherit;
    bottom: 0;
    min-width: -webkit-fill-available;
      height: 43% */
      /*  */
      /* position: fixed;
    bottom: 0;
    min-width: -webkit-fill-available;
    height: 30%; */

   position: relative;
    bottom: 0;
    min-width: -webkit-fill-available;
    /* height: 37%;  */
    
}
html, body {
  height: 100%;
}
#nav {
  padding: 30px;
}
.context-wrapper {
  /* flex: 1 1 300px; */
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%
}
.context {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<style src="vue-date-pick/dist/vueDatePick.css"></style>
