<template>
 <b-container class="customers">
     <b-card  overlay
      :img-src="baseImg"
      img-alt="Card Image"
      img-height="443"
      style="width: 100%;;border: none;">
        <b-container>
            <h2>Customers</h2>
            <b-card-text class="card-text">
             A satisfied customer is the best business strategy of all
            </b-card-text>
        </b-container>
     </b-card>
      <b-container  class="customers-Content mx-auto" >
         
        <b-card-group v-for="group in currentlist" :key="group"  deck style="padding:10px">
         <b-card 
             v-for="item in group" :key="item.id"
              overlay
              :img-src="item.imgSrc"
              img-height="110px"
              img-width= "-webkit-fill-available"
              :img-alt="item.imgAlt"
              style="box-shadow: 0 1rem 1rem rgb(0 0 0 / 18%) !important"
              class="shadow-lg  mb-3 bg-white rounded"
            @click='$router.push({name: "MyCustomers", query: { customer:item.name}})'
            >
            </b-card>
        </b-card-group>

        <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              class="d-flex justify-content-center customPagination" 
        ></b-pagination>


      </b-container>



 </b-container>
</template>

<script>
import baseImg  from "../assets/solutions/solutionsBackground.png";
// import baseImg2  from "../assets/solutions/ODS.png"

export default {
 data() {
      let customersGroup1= [
      {id:1,name:"ADIB", imgSrc:require('../assets/customrs logos/ADIB.png') ,imgAlt:"ADIB Logo" },
      {id:2,name:"zacta", imgSrc:require('../assets/customrs logos/zacta.jpg') ,imgAlt:"zacta logo" },
      {id:3,name:"Saudi Customs", imgSrc:require('../assets/customrs logos/SaudiCustoms.jpg') ,imgAlt:"Saudi Customs" },
      {id:4,name:"Sdb", imgSrc:require('../assets/customrs logos/Sdb.jpg') ,imgAlt:"Sdb Logo" },
     ];
      
      let customersGroup2= [
        {id:1,name:"Cchi", imgSrc:require('../assets/customrs logos/Cchi.jpg') ,imgAlt:"Cchi Logo" },
        {id:2,name:"Hrdf", imgSrc:require('../assets/customrs logos/Hrdf.jpg') ,imgAlt:"Hrdf Logo" },
        {id:3,name:"Ministry of human resources", imgSrc:require('../assets/customrs logos/Ministry of human resources.jpg') ,imgAlt:"Ministry of human resources Logo" },
      ];
      
      let customersGroup3= [
        {id:1,name:"credit", imgSrc:require('../assets/customrs logos/credit.png') ,imgAlt:"credit Logo" },
        {id:2,name:"NCB", imgSrc:require('../assets/customrs logos/NCB.png') ,imgAlt:"NCB Logo" },
        {id:3,name:"Saudi_Telecom_Company", imgSrc:require('../assets/customrs logos/Saudi_Telecom_Company.png') ,imgAlt:"Saudi_Telecom_Company Logo" },
        {id:4,name:"Mobily", imgSrc:require('../assets/customrs logos/Mobily.png') ,imgAlt:"Mobily Logo" }

      ];
      let customersGroup4= [
        {id:1,name:"ryad", imgSrc:require('../assets/customrs logos/ryad.png') ,imgAlt:"ryad Logo" },
        {id:2,name:"Rajhi", imgSrc:require('../assets/customrs logos/Rajhi.png') ,imgAlt:"Rajhi Logo" },
        {id:3,name:"faisal", imgSrc:require('../assets/customrs logos/faisal.png') ,imgAlt:"faisal Logo" },
      ];

;

    return {
        perPage: 3,
        currentPage: 1,
        baseImg,
      customersMainGroup:[customersGroup1,customersGroup2,customersGroup3,customersGroup4]
      
      }},
      computed: {
        currentlist () {
          return this.customersMainGroup.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          )
        },
        rows() {
          return this.customersMainGroup.length
        }
      },
      
}
</script>

<style scoped>
.customers{
    padding-left: inherit;
  padding-right: inherit;
   position: relative;
}
.card-body{
      display: flex;
    justify-content: center;
    align-items: center;
}
h2{
  text-align: left;
      POSITION: relative;
      padding: 20px;
      margin-bottom: 1.5rem;
      color: white;
      text-align: center;
}

h2::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
    left: unset;
    margin: 0 0 -2px -105px;
    position: absolute;
    width: 70px;
}

.card-text{
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    text-align: center;
}
.customers-Content{
    width: 85%;
    border: none;
    padding: 15px;
}
 /* .customPagination  > li > a {
    color: red;
    
  }
 .customPagination  > li.active > a,
.customPagination  > li > a:hover{
    color: white;

     background-color: green!important;} */
</style>