import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Solutions from "../views/Solutions.vue";
import Customers from "../views/Customers.vue";
import MyCustomers from "../components/Customers/MyCustomers.vue";
import Contact from "../components/Home/ContactUs.vue"
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: About
    },
    {
      path: "/MyCustomers",
      name: "MyCustomers",
      component: MyCustomers
    },
    
    {
      path: "/Solutions",
      name: "Solutions",
      component: Solutions
    },
    {
      path: "/Customers",
      name: "Customers",
      component: Customers
    },
    {
      path: "/Contact",
      name: "Contact",
      component: Contact
    },
  ]
});

export default router;
