<template>
    <b-container>
    <b-card  class="mx-auto" style="width: 85%;
    border: none;">
    <h3>Our Customers</h3>
  
   <carousel :autoplay="true" :paginationActiveColor="'#FBB70D'" :paginationPadding="5"  :perPage="1" :adjustableHeight="true" :autoplayTimeout="4000" :centerMode="true" :loop="true" :scrollPerPage="true" :paginationEnabled="true">
  <slide>
   <b-card-group deck >
            <b-card 
             v-for="item in cardsGroup" :key="item.id"
              overlay
              :img-src="item.imgSrc"
              img-height="110px"
              img-width= "-webkit-fill-available"
              :img-alt="item.imgAlt"
            @click='$router.push({name: "MyCustomers", query: { customer:item.name}})'
            >
            </b-card>
        </b-card-group>
        <b-card-group deck style="padding: 10px;" >
          <!-- <b-card >
            <img src="../assets/solutions.png" alt="Bussma Logo" style="height: 110px;    width: -webkit-fill-available;">
            </b-card> -->

         <b-card 
             v-for="item in cardsGroup2" :key="item.id"
              overlay
              :img-src="item.imgSrc"
              img-height="110px"
              img-width= "-webkit-fill-available"
              :img-alt="item.imgAlt"
            @click='$router.push({name: "MyCustomers", query: { customer:item.name}})'
            >
            </b-card>
        </b-card-group>
  </slide>
  <slide>
   <b-card-group deck >
           <b-card 
             v-for="item in cardsGroup3" :key="item.id"
              overlay
              :img-src="item.imgSrc"
              img-height="110px"
              img-width= "-webkit-fill-available"
              :img-alt="item.imgAlt"
            @click='$router.push({name: "MyCustomers", query: { customer:item.name}})'
            >
            </b-card>
        </b-card-group>
        <b-card-group deck style="padding: 10px;" >
         <b-card 
             v-for="item in cardsGroup4" :key="item.id"
              overlay
              :img-src="item.imgSrc"
              img-height="110px"
              img-width= "-webkit-fill-available"
              :img-alt="item.imgAlt"
            @click='$router.push({name: "MyCustomers", query: { customer:item.name}})'
            >
            </b-card>
        </b-card-group>
  </slide>
</carousel>
    </b-card>
    <b-row class="mx-auto"  style="width: 85%;" >
      <b-col md="4" offset-md="4" style="text-align:center;"> <b-button squared  variant="light"  to="/Customers">More Customers</b-button></b-col>
    </b-row>
  </b-container>
</template>

<script>
// import baseImg  from "../../assets/solutions.png"

export default {
data() {
    return {
      cardsGroup: [
      {id:1,name:"ADIB", imgSrc:require('../../assets/customrs logos/ADIB.png') ,imgAlt:"ADIB Logo" },
      {id:2,name:"zacta", imgSrc:require('../../assets/customrs logos/zacta.jpg') ,imgAlt:"zacta logo" },
      {id:3,name:"Saudi Customs", imgSrc:require('../../assets/customrs logos/SaudiCustoms.jpg') ,imgAlt:"Saudi Customs" },
       ],
      
      cardsGroup2: [
        {id:1,name:"Cchi", imgSrc:require('../../assets/customrs logos/Cchi.jpg') ,imgAlt:"Cchi Logo" },
        {id:2,name:"Hrdf", imgSrc:require('../../assets/customrs logos/Hrdf.jpg') ,imgAlt:"Hrdf Logo" },
        {id:3,name:"Ministry of human resources", imgSrc:require('../../assets/customrs logos/Ministry of human resources.jpg') ,imgAlt:"Ministry of human resources Logo" },
        ],
      
      cardsGroup3: [
        {id:1,name:"credit", imgSrc:require('../../assets/customrs logos/credit.png') ,imgAlt:"credit Logo" },
        {id:2,name:"NCB", imgSrc:require('../../assets/customrs logos/NCB.png') ,imgAlt:"NCB Logo" },
        {id:3,name:"Saudi_Telecom_Company", imgSrc:require('../../assets/customrs logos/Saudi_Telecom_Company.png') ,imgAlt:"Saudi_Telecom_Company Logo" },
      ],
      cardsGroup4: [
        {id:1,name:"Mobily", imgSrc:require('../../assets/customrs logos/Mobily.png') ,imgAlt:"Mobily Logo" },
        {id:2,name:"Sdb", imgSrc:require('../../assets/customrs logos/Sdb.jpg') ,imgAlt:"Sdb Logo" },
        {id:3,name:"faisal", imgSrc:require('../../assets/customrs logos/faisal.png') ,imgAlt:"faisal Logo" },

      ]
};
  },
}
</script>

<style scoped>
.card-text{
      color: #6B6B6B;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
}
.card-body {
    padding: unset;
    }
h3{
  text-align: center;
      POSITION: relative;
      padding: 10px;
      margin-bottom: 1.5rem;
}


h3::before {
    background: #ebebeb;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    margin: 0 0 0 -95px;
    position: absolute;
    width: 190px;
}
h3::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
    left: 50%;
    margin: 0 0 -2px -35px;
    position: absolute;
    width: 70px;}
</style>