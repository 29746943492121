<template>


  <b-container class="mycustomer">
    <b-card  overlay :img-src='currentCustomer.imgBackgrond'
      img-alt="Card Image"
      img-height="442"
     style="width: 100%;;border: none;padding-top: 10px;">
      <h1>{{currentCustomer.company}}</h1>
    </b-card>
      <b-container class="mycustomer-Content  mx-auto" >
        <b-row style="padding-top: 15px;">
          <b-col cols="8">
           
             <p>{{currentCustomer.comments}} </p>

            <b-container style="padding-bottom:10px">
              <img src="../../assets/customers backgrounds/solution_icon.png" alt="solution_icon" class="content-img">              
              <b-container class="content-div">
                <h2 class="content-h2">SOLUTION</h2>
              </b-container>
            </b-container>
            <p>{{currentCustomer.solution}}</p>
          </b-col>
          <b-col style="flex: 0 0 33.333333%" class="left-col-content">
            <b-container>
               <h2 class="left-col-content-h2">Company</h2>
              <p>{{currentCustomer.company}}</p>
              <h2 class="left-col-content-h2">Industry</h2>
              <p>{{currentCustomer.industory}}</p>
              <h2 class="left-col-content-h2">Office  </h2>
              <p>{{currentCustomer.office}}</p>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
  </b-container>
</template>

<script>

export default {
  
  data() {
    return {
        customers:[
        {
            name:"zacta",
            imgBackgrond:require('../../assets/customers backgrounds/zacta.jpg'),
            company:"Zakat,Tax and Customs Authority",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Zakat, Tax and Customs Authority established under the Council of Ministers Decision No. (570) dated 22 of Ramadan 1442 AH corresponding to May 4, 2021, to merge the General Authority of Zakat and Tax and the General Customs Authority into one Authority under the name of Zakat, Tax and Customs Authority."
        },
        {
            name:"ADIB",
            imgBackgrond:require('../../assets/customers backgrounds/adibBK.png'),
            company:"ADIB",
            industory:"Banking",
            office:"Egypt",
            solution:"They have been provided with Data Masking and Data Replication.",
            comments:"ADIB Shari’a Compliant Financial Solutions for the Egyptian Community."
        },
        {
            name:"Sdb",
            imgBackgrond:require('../../assets/customers backgrounds/sdb.png'),
            company:"Social Development Bank",
            industory:"Banking",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Governance.",
            comments:"SDB focus's  in providing social financing products and business solutions to the low income citizens, and creating awareness in financial planning & saving , as well as funding freelancers, micro, startup , and small businesses in way to enable them to contribute effectively to the economic growth of  the country. "
        },
        {
            name:"Cchi",
            imgBackgrond:require('../../assets/customers backgrounds/Cchi.jpg'),
            company:"Council Of Health Insurance",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL and Consultancy & Advisory.",
            comments:"Council of cooperative health Insurance is an independent government body built by the decision of the Cabinet of ministers No. 71 and a date 27 / 4 / 1420 H - 11 / 8 / 1999 to regulate the health insurance."
        },
        {
            name:"Hrdf",
            imgBackgrond:require('../../assets/customers backgrounds/Hrdf.jpg'),
            company:"Saudi Human Resources Development Fund",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Big Data and Data Integration & ETL.",
            comments:"The Human Resources Development Fund is a Saudi governmental authority that was established by a royal decree in July 2000. It falls under the Saudi Ministry of Labor and Social Development. HRDF is mainly concerned with providing financial support to organizations that train and qualify Saudis in the private sector."
        },
        {
            name:"Ministry of human resources",
            imgBackgrond:require('../../assets/customers backgrounds/Ministry of human resources.jpg'),
            company:"Ministry of human resources",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Big Data and Data Integration & ETL.Trust is built up over years, and year over year our clients’ trust on us becomes even stronger. CIB, our strategic customer since 2016 builds up on our relationship and selects IME to fulfill the new scope of work for Data Integration, Metadata Management, Data Classification and Data Governance.",
            comments:"The Ministry of Human Resources and Social Development is a government ministry in Saudi Arabia was established in 2019 after merging Ministry of Labour and Social Development with Ministry of Civil Service. It is responsible for providing the community with development, support, and protection."
        },
        {
            name:"Emirates_NBD",
            imgBackgrond:require('../../assets/customers backgrounds/nbd.png'),
            company:"NBD",
            industory:"Banking",
            office:"United Arab Emirates",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Emirates NBD Egypt offers its clients a broad range of sophisticated products and services in three major segments – Corporate Banking, Retail Banking and Investment Solutions. A customer-centric mindset ensures that all products are innovative, effective and address the unique needs of every client."
        },
        {
            name:"mashreq",
            imgBackgrond:require('../../assets/customers backgrounds/mashreqbk.png'),
            company:"Mashreq Bank",
            industory:"Banking",
            office:"United Arab Emirates",
            solution:"They Have been provided with Data Integration & ETL.",
            comments:"Mashreq Bank is one of UAE’s leading financial institutions with a growing retail presence in the region including Egypt, Qatar, Kuwait and Bahrain. We focus on providing our customers access to a wide range of innovative products and services."
        },
        {
            name:"Etisalat",
            imgBackgrond:require('../../assets/customers backgrounds/Etisalat_HQ_-_Egypt.png'),
            company:"Etisalat",
            industory:"Telecommunication",
            office:"Egypt",
            solution:"They have been provided with Data Replication and Data Integration & ETL.",
            comments:"Etisalat Misr is a subsidiary of Etisalat group one of the first telecom operators in the world to launch the 4G with total presence in 16 countries across Africa, Middle East and Asia."
        },
        {
            name:"Orange",
            imgBackgrond:require('../../assets/customers backgrounds/orange.png'),
            company:"Orange",
            industory:"Telecommunication",
            office:"Egypt",
            solution:"They have been provided with Data Archiving.",
            comments:"Al Ahli Bank of Kuwait (ABK) provides superior financial solutions to its clients. Today, ABK offers a full range of products and services ."
        },
        {
            name:"Saudi_Telecom_Company",
            imgBackgrond:require('../../assets/customers backgrounds/stc.png'),
            company:"STC",
            industory:"Telecommunication",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Archiving.",
            comments:"STC is a world-class digital leader providing innovative services and platforms to the customers and enabling the digital transformation of the MENA region."
        },
        {
            name:"Vodafone",
            imgBackgrond:require('../../assets/customers backgrounds/Vodafone_Smart_Village.png'),
            company:"Vodafone",
            industory:"Telecommunication",
            office:"Egypt",
            solution:"They have been provided with Data Archiving.",
            comments:"Enjoy a world where work’s not a chore but technology, innovation and great work environment are. It’s all here at Vodafone."
        },
        {
            name:"hamad-medical-corporation",
            imgBackgrond:require('../../assets/customers backgrounds/Hamad.png'),
            company:"Hamad Medical Corporation",
            industory:"Healthcare",
            office:"Qatar",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Hamad Medical Corporation, established by Emiri decree in 1979, is Qatar’s premier not-for-profit health care provider."
        },
        {
            name:"ministry-of-interior-UAE",
            imgBackgrond:require('../../assets/customers backgrounds/Abu-Dhabi-New-Patrol.png'),
            company:"Abu Dhabi Police",
            industory:"Government",
            office:"United Arab Emirates",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Abu Dhabi Police is the primary law enforcement agency in the Emirate of Abu Dhabi, one of the United Arab Emirates."
        },
        {
            name:"MOF",
            imgBackgrond:require('../../assets/customers backgrounds/mof.png'),
            company:"Ministry of Finance",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"The Ministry of Finance was made responsible for regulating, managing and securing the collection of state funds, and their expenditures, and became the general authority for financial matters in the Kingdom of Hejaz and Nejd and its subsidiaries."
        },
        {
            name:"Mobily",
            imgBackgrond:require('../../assets/customers backgrounds/mobily.png'),
            company:"Mobily",
            industory:"Telecommunication",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Masking.",
            comments:"Mobily is a Saudi company and the commercial name for Etihad Etisalat Co. It is pioneer in the Telecom & Information Technology sector in Saudi Arabia which launched its business on 25th, May, 2005 and became the fastest growing companies in the world and its brand has become one of the strongest brands in the world Telecom sector."
        },
        {
            name:"NBE",
            imgBackgrond:require('../../assets/customers backgrounds/nbe.jpg'),
            company:"NBE",
            industory:"Banking",
            office:"Egypt",
            solution:"Bussma enables National Bank of Egypt (NBE) to increase data processing performance, accelerate order processing speed and reduce recovery response time..",
            comments:"NBE is the oldest commercial bank in Egypt, Started with us to eliminate costly, manual scripting techniques used to integrate data from legacy-system."
        },
        {
            name:"NCB",
            imgBackgrond:require('../../assets/customers backgrounds/ncb.png'),
            company:"NCB",
            industory:"Banking",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Archiving and Consultancy & advisory.",
            comments:"The National Commercial Bank (NCB) is a prominent Saudi Arabian bank and the leading financial institution in the region. The bank is considered the largest and the first to officially be licensed and operate in Saudi Arabia, following the Royal Decree of the 26th of December 1953, corresponding to 20th of Rabi Al-Thani 1373H."
        },
        {
            name:"Al-Bilad-Bank",
            imgBackgrond:require('../../assets/customers backgrounds/al-bilad.png'),
            company:"Bank Albilad",
            industory:"Banking",
            office:"Saudi Arabia",
            solution:"They have been provided with Operation Data Store (ODS) and Data Integration & ETL.",
            comments:"Bank Albilad is a Saudi joint stock company, established vide Royal Decree 48/M on 21/9/1425H (corresponding to 4 November 2004) with a corporate capital of 6,000,000,000 Saudi Riyals."
        },
        {
            name:"Almarai",
            imgBackgrond:require('../../assets/customers backgrounds/Almarai.png'),
            company:"Almarai",
            industory:"Manufacturing",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Almarai is the Middle East’s leading food and beverage manufacturer and distributor – and the world’s largest vertically integrated dairy company."
        },
        {
            name:"Asiacell",
            imgBackgrond:require('../../assets/customers backgrounds/Asiacell-building.png'),
            company:"Asiacell",
            industory:"Telecommunication",
            office:"Iraq",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Asiacell’s products and services have been designed for the people and meeting their needs and offered at prices suitable for them ."
        },
        {
            name:"boursakuwait",
            imgBackgrond:require('../../assets/customers backgrounds/KUWAIT-STOCK-EXCHANFE.png'),
            company:"KUWAIT STOCK EXCHANFE",
            industory:"Government",
            office:"Kuwait",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Boursa Kuwait is responsible for introducing new trading rules, risk management practices, price discovery mechanisms and new technology to ensure that Boursa Kuwait is as robust and secure as possible."
        },
        {
            name:"CAIRO-1",
            imgBackgrond:require('../../assets/customers backgrounds/cairo.jpg'),
            company:"Banque du Caire",
            industory:"Banking",
            office:"Egypt",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Banque du Caire, or “Bank of Cairo”, is a full-service bank headquartered in Cairo. The bank operates over 150 branches in Egypt."
        },
        {
            name:"credit",
            imgBackgrond:require('../../assets/customers backgrounds/credit.png'),
            company:"Credit Agricole Egypt",
            industory:"Banking",
            office:"Egypt",
            solution:"They have been provided with Data Replication.",
            comments:"Crédit Agricole Egypt is a subsidiary of the Crédit Agricole Group, a market leader in Universal Customer-Focused Banking in France and one of the largest banks worldwide."
        },
        {
            name:"Djezzy",
            imgBackgrond:require('../../assets/customers backgrounds/djezzy.png'),
            company:"DJEZZY",
            industory:"Telecommunication",
            office:"Algeria",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Djezzy is Algeria’s principal mobile network operator, with a market share of 65% over 16.49 million subscribers and a network covering 90% of the population."
        },
        {
            name:"Du",
            imgBackgrond:require('../../assets/customers backgrounds/Du.png'),
            company:"Du",
            industory:"Telecommunication",
            office:"United Arab Emirates",
            solution:"They have been provided with Data Quality, Data Replication and Data Integration & ETL.",
            comments:"DU have worked hard to enhance and expand our bouquet of services in an industry that is at the heart of economic and social transformation. Bringing people and businesses together is what we do best, offering mobile and fixed telephony, broadband connectivity and IPTV services to people, homes and businesses all over the UAE."
        }, 
        {
            name:"Elm",
            imgBackgrond:require('../../assets/customers backgrounds/elm.png'),
            company:"Elm",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Elm seeks to build close and strong business relationships with its vendors who have different specialties. These relationships must be built on a fixed set of values, most importantly: trust, credibility, integrity and common interest."
        }, 
        {
            name:"emirates_airlines",
            imgBackgrond:require('../../assets/customers backgrounds/Emirates.png'),
            company:"Emirates",
            industory:"Transportation",
            office:"United Arab Emirates",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Emirates inspire travelers around the world with growing network of worldwide destinations, industry leading inflight entertainment, regionally inspired cuisine, and world-class service."
        }, 
        {
            name:"eskaan",
            imgBackgrond:require('../../assets/customers backgrounds/eskan.png'),
            company:"Ministry of Housing",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Organize and facilitate a balanced and sustainable housing environment."
        }, 
        {
            name:"Extra",
            imgBackgrond:require('../../assets/customers backgrounds/extra.png'),
            company:"Extra",
            industory:"Retail",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"eXtra offers all the leading international brands and stocks an extensive product range including TV, audio systems, computers, mobile phones and IT communications, cameras, home appliances and personal care products."
        }, 
        {
            name:"faisal",
            imgBackgrond:require('../../assets/customers backgrounds/Front-view-Hospital.png'),
            company:"King Faisal Specialist Hospital & Research Centre",
            industory:"Healthcare",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"King Faisal Specialist Hospital & Research Centre provides the highest level of specialized healthcare in an integrated educational and research setting."
        },
        {
            name:"firstBank",
            imgBackgrond:require('../../assets/customers backgrounds/1st-Bank.png'),
            company:"First Bank",
            industory:"Banking",
            office:"Nigeria",
            solution:"They have been provided with Data Masking.",
            comments:"With total assets of N3.3 trillion, customer deposits of N2.6 trillion and a strong capital adequacy ratio of 18.9%, well above the CBN mandated minimum of 10% FirstBank is the largest bank in Nigeria, and amongst the largest corporate and retail financial institutions in sub-Saharan Africa (excluding South Africa)."
        }, 
        {
            name:"GACA",
            imgBackgrond:require('../../assets/customers backgrounds/photo-5-color.jpg'),
            company:"General Authority of Civil Aviation",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"The General Authority of Civil Aviation (GACA) of the Kingdom of Saudi Arabia emerged from the Presidency of Civil Aviation in 2006."
        }, 
        {
            name:"Inma",
            imgBackgrond:require('../../assets/customers backgrounds/al-inma.png'),
            company:"Alinma Bank",
            industory:"Banking",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Governance, Data Quality, Meta Data Management and Consultancy & Advisory.",
            comments:"Alinma Bank is licensed to operate in the Saudi financial services sector, a sector that has experienced positive growth in recent years and is vital to the strength of the national economy."
        }, 
        {
            name:"MTN",
            imgBackgrond:require('../../assets/customers backgrounds/mtn.png'),
            company:"MTN Bank",
            industory:"Banking",
            office:"Nigeria",
            solution:"They have been provided with Data Archiving, Operation Data Store and Data Integration & ETL.",
            comments:"MTN is a pure-play emerging markets mobile telecommunications operator at the forefront of technological and digital changes."
        },
        {
            name:"Ooredoo",
            imgBackgrond:require('../../assets/customers backgrounds/mtn.png'),
            company:"Ooredoo",
            industory:"Telecommunication",
            office:"Qatar",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"Ooredoo QSC is an international telecommunications company headquartered in Doha, Qatar."
        },
        {
            name:"OSN",
            imgBackgrond:require('../../assets/customers backgrounds/osn.png'),
            company:"OSN",
            industory:"Entertainment",
            office:"United Arab Emirates",
            solution:"They have been provided with Data Governance.",
            comments:"At OSN, we love epic entertainment and premium storytelling, which is why we pride ourselves on providing world-class TV to millions of households and businesses across the MENA region."
        },
        {
            name:"qatar_airways",
            imgBackgrond:require('../../assets/customers backgrounds/quatar-airways.jpg'),
            company:"Qatar Airway",
            industory:"Transportation",
            office:"Qatar",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"QATAR AIRWAY connect more than 150 destinations on the map every day, with a fleet of the latest-generation aircraft, and an unrivalled level of service from our home and hub, the Five-star airport, Hamad International Airport in Doha, the State of Qatar."
        },
        {
            name:"Rajhi",
            imgBackgrond:require('../../assets/customers backgrounds/alraghi.png'),
            company:"Al Rajhi Bank",
            industory:"Banking",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL and Consultancy & Advisory.",
            comments:"Al Rajhi Bank is one of the largest Islamic banks in the world with total assets of SR 343 billion (US$ 90 billion), a paid up capital of SR 16.25 billion (​US$ 4.33 billion) and an employee base of over 9,600 associates."
        },
        {
            name:"ryad",
            imgBackgrond:require('../../assets/customers backgrounds/Riyad-Bank.png'),
            company:"Riyad Bank",
            industory:"Banking",
            office:"Saudi Arabia",
            comments:"Riyad Bank is one of the largest financial institutions in Saudi Arabia, ranked third in assets.",
            solution:"They have been provided with Data Integration & ETL, Data Quality, Data Masking, Data Archiving, Data Governance, Metadata Management, ODS and Consultancy & Advisory Packages"
        },
        {
            name:"SAGIA",
            imgBackgrond:require('../../assets/customers backgrounds/SAGIA.png'),
            company:"SAGIA",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Integration & ETL.",
            comments:"The Saudi Arabian General Investment Authority (SAGIA) is responsible for following up and assessing investment performance, alleviating the difficulties encountered by investors, conducting studies and research and drafting executive plans to stimulate local investments."
        },
        {
            name:"samba",
            imgBackgrond:require('../../assets/customers backgrounds/Samba.png'),
            company:"Samba",
            industory:"Healthcare",
            office:"Qatar",
            solution:"They have been provided with Data Masking.",
            comments:"Ever since our first branch was established in Jeddah by Citibank in 1955, we have upheld a history of excellence. Samba came into being through a Royal Decree in 1980 and has now expanded into one of the foremost financial institutions in the Middle East."
        },
        {
            name:"Saudi Customs",
            imgBackgrond:require('../../assets/customers backgrounds/SaudiCustoms.jpg'),
            company:"Saudi Customs",
            industory:"Government",
            office:"Saudi Arabia",
            solution:"They have been provided with Data Archiving, Operation Data Store and Data Integration & ETL.",
            comments:"Saudi Customs announced the start of TIR operations in the Kingdom of Saudi Arabia at several Customs outlets including Al-Batha, Al-Khafji, Al-Raqi, Al-Haditha and Halat Ammar Customs.“The start of TIR operations in the Kingdom represents an important step for Saudi Customs towards the harmonization and facilitation of customs transit procedures between TIR contracting parties."
        },
        ],
        currentCustomer:null
    }
  },
  created:function(){
         this.currentCustomer= this.customers.find(x => x.name == this.$route.query.customer)
    },
}
</script>

<style  scoped>
.mycustomer{
    padding-left: inherit;
  padding-right: inherit;
   position: relative;
}
.mycustomer-Content{
  width: 85%;
    border: none;
    padding: 15px;
}
h1{
  text-align: left;
      POSITION: relative;
      color:rgb(251,183,13);
    font-size: 32px;
    margin: 0;
    padding:60px 50px 13px;
}



/* h1::after 
{
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
        left: unset;
    margin: 0 0 -2px -65px;
    position: absolute;
    width: 70px;
} */
.content-img{
    height: 65px;
    width: 55px;
    float: left;
    padding: 0 15px 15px 0;
}
.content-h2{
        font-family: 'Raleway';
      font-size: 23px;
    margin: 0;
    padding: 10px 10px 15px;
    font-weight: 600;
    color: rgb(251,183,13);
}
.content-div
{
  overflow: hidden;
}
p{
      color: #6B6B6B;
    word-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
}
.left-col-content{
  padding: 20px 20px 0 20px;
    display: block;
    border: 1px solid #ebebeb;
    box-shadow: 0 0 13px #e9e9e9;
    margin: 0 0 40px;
}
.left-col-content-h2{
        font-family: 'Raleway';
      font-size: 23px;
    margin: 0;
    font-weight: 600;
    color: rgb(251,183,13);
}
</style>

