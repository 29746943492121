<template>
  <b-container class="solutions">
     <b-card  overlay
      :img-src="baseImg"
      img-alt="Card Image"
      img-height="600"
      style="width: 100%;;border: none;">
        <b-container>
            <h2>SOLUTIONS</h2>
            <b-card-text class="card-text">
              we create the business processes required to ensure quality is built into the core of our services.
            </b-card-text>
        </b-container>
     </b-card>
    <b-container class="solutions-Content mx-auto" >
     <b-row>
        <b-col>
        <img src="../assets/solutions/ODS.png" alt="Operational Data Store" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Operational Data Store (ODS)</h1>
          <p class="content-p">
            Many organizations builds the Operational Stores to provide online services without increasing the load on operational systems. Building ODS requires large experience in Architecture and Data and Applications Integration.
          </p>
          <p class="content-p">
            <strong>Bussma</strong>  have worked with many customers in Middle East to build the real-tBussma ODS and help organizations providing online services to its customers and internal users.
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/dataGovernance.png" alt="Data Governance" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Governance</h1>
          <p class="content-p">
            Data Governance is the heart of Data Management projects. Without “Governance”, the percentage of failure for other projects exceeds 70%. Following DAMA standard and considering best practices.        
          </p>
          <p class="content-p">
            <strong>Bussma</strong>  is providing Data Governance consultancy, implementation and tools for many organizations in the region. in addition, Bussma is a key player in building organization’s “Data Management Strategy” Bussma provides specific industries solutions such as GDPR .
          </p>
        </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <img src="../assets/solutions/Data Archiving&Retirement.png" alt="Data Archiving & Retirement" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Archiving & Retirement</h1>
          <p class="content-p">
            Control data growth in production databases and retire legacy applications, while managing retention, ensuring compliance, and retaining access to business data is a key requirement for all big organizations.        </p>
          <p class="content-p">
            <strong>Bussma</strong>  provides the best tools to enhance the customer operating production database environments and reduce the cost of storing the production data. Bussma is #1 in Middle East in providing large Data Archiving projects. In addition to the tools, Bussma consultants are recognized by the customers as the regional domain expertscustomers and internal users.
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/Big Data.png" alt="Big Data" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Big Data</h1>
          <p class="content-p">
            Big Data Management enables your organization to process large, diverse, and fast changing data sets so you can get insights into your data.
          <p class="content-p">
            Use Big Data Management to perform big data integration and transformation without writing or maintaining Apache Hadoop code.
          </p>        
          <p class="content-p">
            <strong>Bussma</strong>  is providing the tools and experience that is used in the implementation of Big Data projects. This includes also the projects related to reading from social media such as Facebook, Twitter, LinkedIn, etc.
          </p>
        </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <img src="../assets/solutions/Data Integration & ETL.png" alt="Data Integration & ETL" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Integration & ETL</h1>
          <p class="content-p">
            By providing a robust integrated codeless environment, teams can collaboratively connect systems and transform and integrate data at any scale and any speed. 
          </p>      
          <p class="content-p">
            <strong>Bussma</strong> is providing data integration tools and project implementations for different types of customers/industries including both batch (ETL) integration and Real tBussma integration. Bussma is providing the market leaders tools and the best practice implementation depends on its very high experienced and skilled team. Around 50 customers in Middle East and Africa (MEA) are depending on Bussma team and tools for their Data Integration projects.
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/Data Replication & Synchronization.png" alt="Data Replication & Synchronization" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Replication & Synchronization</h1>
          <p class="content-p">
            In today's complex customer environment, data replication and synchronization are very challenging for most of Information Management Organizations. Therefore. 
          </p>
          <p class="content-p">
            <strong>Bussma</strong> is it providing the proper tool and implement it in a real tBussma mode.
          </p>        
          <p class="content-p">
            <strong>Bussma</strong> is providing the market leaders tools that allows data synchronization between same or different types of databases in a real tBussma mode.
          </p>
        </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <img src="../assets/solutions/Data Integration & ETL.png" alt="Data Warehousing" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Warehousing</h1>
          <p class="content-p">
            <strong>Bussma</strong> team works with the world wide Data Warehouse appliances providers such as EMC2 and Teradata and have implementations on other databases such as Oracle and Microsoft SQL.
          </p>
          <p class="content-p">
          We are working with large data sets in a data warehouse environment to create dashboards and Visual Analytics.
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/Data Quality.png" alt="Data Quality" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Quality</h1>
          <p class="content-p">
          Data Quality is a main challenge for most organizations worldwide, specifically in the customer and products domains.
          </p>
          <p class="content-p">
            <strong>Bussma</strong> is providing Data quality tools and implementations to solve customer challenges.
          </p>
        </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <img src="../assets/solutions/Data Migration.png" alt="Data Migration" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Migration</h1>
          <p class="content-p">
              Digitalization and business initiatives require the moving to modern techniques and new systems. A major challenge in the movement to new systems is the Data Migration.        
          </p>
          <p class="content-p">
            <strong>Bussma</strong> is the expert in consulting about best migration plans and is providing the required tools and services for implementing migration plans.
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/Metadata Management.png" alt="Metadata Management" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Metadata Management</h1>
          <p class="content-p">
            Understanding the information related to the organization data and how data moves between systems is a major requirement for all organization to increase the governance and reduce the redundant work
          </p>
          <p class="content-p">
            <strong>Bussma</strong> is a market leader in the region for building Business and Technical Data Catalogs and identifying organizations Data Linage.
          </p>
        </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <img src="../assets/solutions/Data Masking.png" alt="Data Masking" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Data Masking</h1>
          <p class="content-p">
            Protects unauthorized access to and disclosure of sensitive, private, and confidential information.        </p>
          <p class="content-p">
            <strong>Bussma</strong> is providing its capabilities to implement a database masking projects.
          </p>
          <p class="content-p">
            <strong>Bussma</strong> methodology is to maintain the data integrity for masked systems/databases
          </p>
        </b-container>
        </b-col>
        <b-col>
          <img src="../assets/solutions/Master Data Management MDM.png" alt="Master Data Management MDM" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Master Data Management MDM</h1>
        <p class="content-p">
            <strong>Bussma</strong> is providing the world wide certified tools for implementing Master Data Management projects backed-up by an experienced implementation team. The scopes covers multi domains such as customers and products domains.
          </p>
          <p class="content-p">
            <strong>Bussma</strong> has pre-built models for different types of customers including Banking and Financial Services, Telecommunications, Health Providers, etc.
          </p>
        </b-container>
        </b-col>
      </b-row> 
      <b-row>
        <b-col>
        <img src="../assets/solutions/Test Data Management.png" alt="Test Data Management" class="content-img">
        <b-container class="content-div">
          <h1 class="content-h1">Test Data Management</h1>
          <p class="content-p">
            Test Data Management (TDM) integrates with PowerCenter, PowerExchange, and Informatica applications to manage nonproduction data in an organization.
            </p>
          <p class="content-p">
            <strong>Bussma</strong> is providing the tools and implementation to allow customers to manage non-production environments data (development, testing, SIT and UAT environments). Pre-built packages for Siebel, Oracle e-Business suite, SAP, PeopleSoft are being used to insure best practices.
          </p>
        </b-container>
        </b-col>
        <b-col></b-col>
      </b-row>

    </b-container>

  </b-container>
</template>

<script>
import baseImg  from "../assets/solutions/solutionsBackground.png";
export default {

  data() {
    return {
      baseImg,
      SolutionsContent:[
        {
          id:1,
          title:"Operational Data Store (ODS)",
          imgSrc:"../assets/solutions/ODS.png",
          paragraphs:[
            "Many organizations builds the Operational Stores to provide online services without increasing the load on operational systems. Building ODS requires large experience in Architecture and Data and Applications Integration."
          ,
            "<strong>Bussma</strong>  have worked with many customers in Middle East to build the real-tBussma ODS and help organizations providing online services to its customers and internal users."
          ]
        },
         {
          id:2,
          title:"Operational Data Store (ODS)",
          imgSrc:"../assets/solutions/ODS.png",
          paragraphs:[
            "Many organizations builds the Operational Stores to provide online services without increasing the load on operational systems. Building ODS requires large experience in Architecture and Data and Applications Integration."
          ,
            "<strong>Bussma</strong>  have worked with many customers in Middle East to build the real-tBussma ODS and help organizations providing online services to its customers and internal users."
          ]
        }
      ]
      
      
      
      
      
    }
  },
}
</script>

<style scoped>
.solutions{
    padding-left: inherit;
  padding-right: inherit;
   position: relative;
   /* padding-bottom: 14rem; */
}
.card-body{
      display: flex;
    justify-content: center;
    align-items: center;
}
h2{
  text-align: left;
      POSITION: relative;
      padding: 20px;
      margin-bottom: 1.5rem;
      color: white;
      text-align: center;
}

h2::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
    left: unset;
    margin: 0 0 -2px -123px;
    position: absolute;
    width: 70px;
}
.card-img{
      height: 443px;
}
.card-text{
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    width: 68%;
    text-align: center;
}
.solutions-Content{
  width: 85%;
    border: none;
    padding: 15px;
}
.content-p{
      font-family: 'Raleway';
    line-height: 1.7;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 15px;
}
.content-img{
      height: 93px;
    width: 80px;
    float: left;
    padding: 0 15px 15px 0;
}
.content-h1{
        font-family: 'Raleway';
      font-size: 26px;
    margin: 0;
    padding: 0 0 15px;
    font-weight: 600;
    color: rgb(251,183,13);
}
.content-div
{
  overflow: hidden;
}
</style>