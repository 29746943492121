<template>
  <div class="home">
    <about></about>
    <solutions></solutions>
    <customers></customers>
    <contactUs></contactUs>
  </div>
</template>

<script>
import  about  from "../components/Home/About.vue";
import  Solutions  from "../components/Home/Solutions.vue";
import  Customers  from "../components/Home/Customers.vue";
import  ContactUs  from "../components/Home/ContactUs.vue";

export default {
  name: "home",
  components:{about,Solutions,Customers,ContactUs},
};
</script>
<style scoped>
.home{
    padding-left: inherit;
  padding-right: inherit;
   position: relative;
}


</style>
