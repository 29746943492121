<template>
  <b-container>
    <b-card  overlay
    :img-src="baseImg"
    img-alt="Card Image"
    img-height="500"
    title="CONTACT US"
     style="width: 100%;;border: none;">
    <b-card-text text-tag="b-container">
      <b-row>
      <b-col>
        <p>We'd love to hear from you!</p>
        <h4>TECHNICAL INQUIRIES</h4>
        <p>LandLine: +20 3590 9938</p>
        <p>Mobile: +20 109 624 4235</p>
        <p>Mobile: +20 155 099 1352</p>
        
      </b-col>  
       <b-col>
        <h4>GENERAL INQUIRIES</h4>
        <p>info@bussma.com</p>
        <h4>Head Office</h4>
        <p>4 Mansour Fahmy st, 4th Floor</p>
        <p>Office #402 P.O.2525</p>
        <p>Alexandria A.R.E</p>
      </b-col> 
      </b-row>
    </b-card-text>
    </b-card>
  </b-container>
</template>

<script>
import baseImg  from "../../assets/contact us.png";

export default {
  data() {
    return {baseImg}},
}
</script>

<style scoped>
.card-text{
      color: white;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
}
/* .card-body {
    padding-left: 150px;
    padding-top: 30px;
    } */
h3,h4{
  text-align: left;
      POSITION: relative;
      padding: 20px;
      margin-bottom: 1.5rem;
      color: white;
}
p{
  padding-left: 20px;
}


h3::before {
    background: #ebebeb;
    bottom: 0;
    content: "";
    left: unset;
    height: 1px;
    margin: 0 0 0 0px;
    position: absolute;
    width: 190px;
}
h4::before {
    background: #ebebeb;
    bottom: 0;
    content: "";
        left: unset;
    height: 1px;
    margin: 0 0 0 0px;
    position: absolute;
    width: 190px;
}
h3::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
        left: unset;
    margin: 0 0 -2px -180px;
    position: absolute;
    width: 70px;}
/* h4::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
        left: unset;
    margin: 0 0 -2px -180px;
    position: absolute;
    width: 70px;} */
</style>