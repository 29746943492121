<template>
  <b-container>
    <b-card class="mx-auto"  style="width: 85%;
    border: none;">
    <h3>Solutions</h3>
    <b-card-group deck style="padding: 10px;" >
    <b-card >
       <img src="../../assets/solutions.png" alt="Bussma Logo" style="height: 110px;    width: -webkit-fill-available;">
       <h5 style="text-align: center;    margin-top: 0.5rem;">Data Quality</h5>
      <b-card-text style="padding: 10px;">
        Bussma is providing Data quality tools and implementations to solve customers challenges .
      </b-card-text>
    </b-card>

    <b-card >
       <img src="../../assets/solutions.png" alt="Bussma Logo" style="height: 110px;    width: -webkit-fill-available;">
       <h5 style="text-align: center;    margin-top: 0.5rem;">Data Integration</h5>
      <b-card-text style="padding: 10px;">
       Bussma is providing data integration tools and project implementations for different types of customers/industries including both batch (ETL) integration and Real time integration.
      </b-card-text>
    </b-card>

  <b-card >
       <img src="../../assets/solutions.png" alt="Bussma Logo" style="height: 110px;    width: -webkit-fill-available;">
       <h5 style="text-align: center;    margin-top: 0.5rem;">Data Migration</h5>
      <b-card-text style="padding: 10px;">
        Bussma is the expert in consulting about best migration plans and is providing the required tools and services for implementing migration plans.      </b-card-text>
    </b-card>
  </b-card-group>
 </b-card>
  <b-row style="width: 85%;" class="mx-auto" >
    <b-col md="4" offset-md="4" style="text-align:center;"> <b-button squared  variant="light"  to="/Solutions">More Solutions</b-button></b-col>
  </b-row>
  </b-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.card-text{
      color: #6B6B6B;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
}
.card-body {
    padding: unset;
    }
h3{
  text-align: center;
      POSITION: relative;
      padding: 10px;
      margin-bottom: 1.5rem;
}


h3::before {
    background: #ebebeb;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    margin: 0 0 0 -95px;
    position: absolute;
    width: 190px;
}
h3::after {
    background: rgb(251,183,13);
    bottom: 0;
    content: "";
    height: 5px;
    left: 50%;
    margin: 0 0 -2px -35px;
    position: absolute;
    width: 70px;}
</style>